import React from "react";
import PropTypes from "prop-types";
import Logo from "../images/logo_zenops_large_white.svg";

const Footer = ({ isCase }) => {
  return (
    <div className="bg-gray-900">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="xl:col-span-1">
            <a href="/">
              <img className="h-10" src={Logo} alt="Zenops" />{" "}
            </a>
            <p className="mt-8 text-gray-300 text-base leading-6">
              Les Ops dans une nouvelle dimension.
            </p>
            <div className="mt-8 flex">
              <a
                href="https://www.linkedin.com/company/zen-ops/"
                target="_blank"
                rel="noopener"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">LinkedIn</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 30 30"
                >
                  <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"></path>
                </svg>
              </a>
              {/* <a
                href="https://twitter.com/zenops360"
                target="_blank"
                rel="noopener"
                className="ml-6 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Twitter</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a> */}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8 text-gray-400">
              <div>
                <a href="/">
                  <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-300 uppercase">
                    Accueil
                  </h4>
                </a>
              </div>
              <div className="mt-12 md:mt-0">
                <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-300 uppercase">
                  Expertises
                </h4>
                <ul className="mt-4">
                  <li>
                    <a
                      href="/moderniser"
                      className="text-base  hover:text-primary-900"
                    >
                      Moderniser
                    </a>
                  </li>

                  <li>
                    <a
                      href="/securiser"
                      className="text-base hover:text-primary-900"
                    >
                      Sécuriser
                    </a>
                  </li>
                  <li>
                    <a
                      href="/simplifier"
                      className="text-base hover:text-primary-900"
                    >
                      Simplifier
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="text-gray-400">
                <h4 className="text-sm font-semibold tracking-wider text-gray-300 uppercase">
                  Solutions
                </h4>
                <ul className="mt-4">
                  <li>
                    <a
                      href="/zenops-saltstack"
                      className="text-base hover:text-primary-900"
                    >
                      SaltStack
                    </a>
                  </li>
                  <li>
                    <a
                      href="/zenops-suse"
                      className="text-base leading-6 hover:text-primary-900"
                    >
                      SUSE
                    </a>
                  </li>
                  <li>
                    <a
                      href="/suse-rancher"
                      className="text-base leading-6 hover:text-primary-900"
                    >
                      Rancher
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0 text-gray-400">
                <h4 className="text-sm font-semibold tracking-wider text-gray-300 uppercase">
                  Entreprise
                </h4>
                <ul className="mt-4">
                  <li>
                    <a
                      href="/events"
                      className="text-base hover:text-primary-900"
                    >
                      Nos Évènements
                    </a>
                  </li>
                  <li>
                    <a
                      href="/about"
                      className="text-base hover:text-primary-900"
                    >
                      À propos
                    </a>
                  </li>
                  <li>
                    <a
                      href="/legal"
                      className="text-base hover:text-primary-900"
                    >
                      Mentions Légales
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base leading-6 text-gray-400 xl:text-center">
            &copy; {new Date().getFullYear()} ZenOps, Tous droits réservés.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

Footer.propTypes = {
  isCase: PropTypes.bool,
};

Footer.defaultProps = {
  isCase: false,
};
