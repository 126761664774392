/* eslint no-unused-expressions: 0 */

import React from "react";
import PropTypes from "prop-types";

import SEO from "./SEO";
import "../../src/style/style.scss";

const Layout = props => (
  <div className={`bg-${props.bg}`}>
    <SEO
      title={props.title}
      description={props.description}
      thumbnail={props.thumbnail}
    />
    {props.children}
  </div>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired
};

export default Layout;
